<template>
  <div class="inner-layout">

    <div class="layout-filter-box">
      <mini-filter :opt="opt.cateCode" :target="cateCode" :targetTitle="'cateCode'" :title="'카테고리'" :inputType="'radio'" @set="setMiniFilter"/>
    </div>
    
    <div class="w-960 mx-auto">
      <div class="sort-box">
        <div v-for="(item, index) in opt.sort" :key="`sort${index}`" :class="{'on' : item.value === sort}" @click="setSort(item.value)">{{ item.text }}</div>
      </div>

      <div class="community-list" v-if="list && list.length > 0">
        <template v-for="item in list">
          <div class="community-item" @click="goDetail(item.boIdx)" >
            <div class="item-img" v-if="item.thumbnail !== null">
              <div class="thumb-img"><image-alt :src="item.thumbnail" altType="list"></image-alt></div>
            </div>
            <div class="item-con">
              <span class="color-1">[{{item.categoryName}}]</span>
              <div class="item-title">{{ item.title }}</div>
              <div class="info">
                <span class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span> · <span>댓글 {{item.commentCount}}ㆍ추천 {{item.likeCnt}}ㆍ조회 {{item.view}}</span>
              </div>
            </div>
          </div>
        </template>

      </div>
      <div v-else class="noDataMessage" style="border: 0">{{ $msg('nodata2') }}</div>
    </div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>

  </div>
</template>
<script>
import listMixin from "@/mixins/listMixin"
export default {
  name: "ggomunityList",
  mixins: [listMixin],
  data() {
    return {
      cateCode: 80,
    }
  },
  created() {
    this.setOptCateCode()
  },
  methods: {
    async setOptCateCode() {
      const { result, data } = await this.$api.getCtgry({ cate: this.cateCode });
      if (result === 'success' && data.length > 0) {
        this.opt.cateCode = data.reduce((list, data) => {
          data.text = data.categoryName
          data.value = data.cateCode
          list.push(data)
          return list
        }, [])
        this.opt.cateCode.unshift({ text: '전체', value: this.cateCode },)
      }
    },
    setMiniFilter(target, value) {
      this[target] = value
    },
    goDetail(id) {
      this.setBeforeContent()
      this.$router.push(`/ggomunity/${id}`)
    }
  }
}
</script>
